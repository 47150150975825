<template>
  <v-alert
    text
    color="primary"
  >
    <h3 class="text-h5">
      {{ $t('booking.resources.title', locale) }}
    </h3>
    <div>
    </div>

    <v-divider
      class="my-4 info"
      style="opacity: 0.22"
    />

    <v-row
      align="center"
      no-gutters
    >
      <v-col>
        <v-btn
          color="primary"
          outlined
          @click="handleAddItem(true)"
        >
        {{ $t('booking.resources.addResource', locale) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  props: {
    locale: {
      type: String,
      default: null,
    },
    onAdd: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleAddItem (v) {
      this.onAdd(v)
    },
  },
}
</script>

